import './app.css'
import './scss/style.scss'
import React, { useState, useEffect } from 'react'
import { AppContext } from './lib/contextLib'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import runOneSignal from './onesignal'
import axios from './axios/index.js'

const MyProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [userToken, setUserToken] = useState('')
  const [currentUser, setCurrentUser] = useState([])
  const [userSubscription, setUserSubscription] = useState({})
  // const [userTenancies, setUserTenancies] = useState()
  // const [userUnits, setUserUnits] = useState()
  // const [userProperties, setUserProperties] = useState()
  //console.log('userToken: ' + userToken)

  useEffect(() => {
    // runOneSignal()
  }, [])

  const [subscription, setSubscription] = useState({})

  useEffect(async () => {
    if ('serviceWorker' in navigator) {
      const handleServiceWorker = async () => {
        const register = await navigator.serviceWorker.register('/sw.js')

        const subscription = await register.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey:
            'BIW2rvAEi6SQk_gzpKKXABU1JRfZp3Zenkib_7kzOVZ9_z_-jimzE_jYOG0ufVFJ4Cx_LVkQaUHjj-wy9FAGoFQ',
        })

        await axios
          .post('/notification/tenant/subscribe', { subscription })
          .then((res) => {
            setSubscription(res.data)
          })
          .catch((error) => {
            console.log(error.message)
          })
      }
      handleServiceWorker()
    }
  }, [])

  useEffect(() => {
    if (subscription.id) {
      setUserSubscription(subscription)
    }
  }, [subscription])

  return (
    <AppContext.Provider
      value={{
        loggedIn: [isAuthenticated, setIsAuthenticated],
        token: [userToken, setUserToken],
        user: [currentUser, setCurrentUser],
        userSubscription: [userSubscription, setUserSubscription],
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

const loading = (
  <div className='pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse'></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'))

// Pages
const AccountBox = React.lazy(() => import('./views/pages/accountBox'))
const Error404 = React.lazy(() => import('./views/pages/error'))
const ActivateAcc = React.lazy(() =>
  import('./views/pages/accountBox/Activated.js')
)
const ErrorAcc = React.lazy(() => import('./views/pages/accountBox/AccError'))

/*const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))*/

function App() {
  return (
    <MyProvider>
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path='/Login'
              name='Login or Register'
              render={(props) => <AccountBox {...props} />}
            />
            <Route
              path='/activated'
              name='Aropmis | Activate Account'
              render={(props) => <ActivateAcc {...props} />}
            />
            <Route
              path='/accerror'
              name='Aropmis | Error 404'
              render={(props) => <ErrorAcc {...props} />}
            />
            <Route
              path='/'
              name='Home'
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </MyProvider>
  )
}

export default App

/*function App() {
  return <AccountBox />
}

export default App*/

/*React.StrictMode disabled due to Material UI Error. If MUI is updated to use useRef then go back to strict mode
  // <React.StrictMode>
  <Router>
    <Switch>
      <Route exact path='/'>
        <App />
      </Route>
      <Route path='/activated'>
        <Activated />
      </Route>
      <Route path='/accerror'>
        <AccError />
      </Route>
      <Route path='*'>
        <Error />
      </Route>
    </Switch>
  </Router>,
  // </React.StrictMode>,*/
